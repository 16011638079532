<template>
  <div class="serveBindingManage">
    <ds-header title="通行领券"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目">
        <a-select style="width: 200px" placeholder="请选择" v-model="searchForm.projectId" allowClear>
          <a-select-option v-for="item in projectList" :key="item.id" :value="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="活动名称">
        <a-input v-model.trim="searchForm.activityName" placeholder="请输入活动名称" class="inputClass" />
      </a-form-item>
      <a-form-item label="关联优惠券ID">
        <a-input v-model.trim="searchForm.couponId" placeholder="请输入活动关联券ID" class="inputClass" />
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="add()"> <a-icon type="plus" /> 新增 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table :rowKey="(record, index) => index" :columns="table.columns" :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false" @change="pageChange" bordered
        :scroll="{ x: 1500 }">
        <span slot="projectNames" slot-scope="text, record">
          <span>{{ JSON.parse(record.projectNames).join(',') }}</span>
        </span>
        <span slot="status" slot-scope="text, record">
          <span v-if="record.status === 1" style="color: rgb(154, 214, 202);">启用</span>
          <span v-else-if="record.status === 0">禁用</span>
          <span v-else>-</span>
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="amend(record)" v-if="record.status === 0"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="restart(record)">
            <span v-if="record.status === 0">启用</span>
            <span v-else>禁用</span>
          </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/passReceiveCouponManagement';
import { SUCCESS_CODE } from '@/config';
export default {
  name: "LimitedTimeCoupon",
  data() {
    return {
      status: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '启用',
          value: 1
        },
        {
          label: '禁用',
          value: 0
        }
      ],
      searchForm: {
        projectId: undefined,
        activityName: '',
        couponId: ''
      },
      // 项目名称
      projectList: [],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "活动ID",
            dataIndex: "activityId",
            key: "activityId",
          },
          {
            title: "所属项目",
            key: "projectNames",
            scopedSlots: { customRender: "projectNames" },
          },
          {
            title: "关联优惠券ID",
            dataIndex: "couponId",
            key: "couponId",
          },
          {
            title: "活动名称",
            dataIndex: "activityName",
            key: "activityName",
          },
          {
            title: "活动更新时间",
            dataIndex: "updateTime",
            key: "updateTime",
          },
          {
            title: "状态",
            key: "status",
            scopedSlots: { customRender: "status" },
          },
          {
            title: "活动有效期",
            dataIndex: "effectiveTime",
            key: "effectiveTime",
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          }
        }
      }
    }
  },
  methods: {
    // 搜索
    search() {
      this.table.pagination.current = 1;
      this.getCouponActivityList();
    },

    // 重制
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = {
        projectId: undefined,
        activityName: '',
        couponId: ''
      }
      this.getCouponActivityList();
    },

    // 新增
    add() {
      this.$router.push({
        path: "/passReceiveCouponManagement/add",
        query: {
          type: "add",
        },
      });
    },

    // 查看
    view(record) {
      const { activityId } = record;
      this.$router.push({
        path: "/passReceiveCouponManagement/add",
        query: {
          id: activityId,
          type: "view",
        },
      });
    },
    // 修改
    amend(record) {
      const { activityId } = record;
      this.$router.push({
        path: "/passReceiveCouponManagement/add",
        query: {
          id: activityId,
          type: "edit",
        },
      });
    },

    // 启用禁用
    restart(record) {
      const self = this;
      let status = ''
      let title = ''
      if (record.status === 0) {
        title = "确定启用该 【 " + record.activityName + " 】 活动吗？"
        status = 1;
      } else {
        title = "确定禁用该 【 " + record.activityName + " 】 活动吗？"
        status = 0;
      }
      this.$confirm({
        title: title,
        onOk() {
          self.couponActivityDisabled(record.activityId, status);
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getCouponActivityList();
    },

    /**网络请求 */
    // 获取项目列表
    async getProjects() {
      try {
        const res = await api.getProjects();
        if (res.code === SUCCESS_CODE) {
          this.projectList = res.data.items;
        }
      } catch (error) {

      }
    },
    // 获取优惠券活动列表
    async getCouponActivityList() {
      const res = await api.getCouponActivityList({
        pageSize: this.table.pagination.pageSize,
        pageNum: this.table.pagination.current,
        ...this.searchForm
      });
      const { code, data: { items, total } } = res
      if (code === SUCCESS_CODE) {
        this.table.dataInfo = items;
        this.table.pagination.total = total
      }
    },
    // 优惠券活动启禁用
    async couponActivityDisabled(id, disabled) {
      try {
        const res = await api.couponActivityDisabled(id, disabled);
        if (res.code === SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.getCouponActivityList();
        } else {
          this.$message.success(res.msg);
        }
      } catch (error) {
        
      }
    }
  },
  created() {
    this.getProjects();
    this.getCouponActivityList();
  },
}
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}

.action a {
  color: #1890ff;
  margin-right: 15px;
}

.action a:hover {
  color: #40a9ff;
}

.inputClass {
  width: 200px;
}

.imgContainer {
  width: 80px;
  height: 80px;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
